<template>
  <div>
    <div v-if='inputType === "password"' @click='toggleFn("text")'>
      <svg t="1603980571380" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9537" width="18" height="18"><path d="M1023.9 511.7h-0.5C936.1 306.8 758 160.4 513.2 159.6h-0.8c-246 0-425 147-512.4 352.6 0 0 0 0.1 0.1 0.1h0.5C87.9 717.2 266 863.6 510.8 864.4h0.8c246 0 425-147 512.4-352.6l-0.1-0.1zM512.1 747.4C382 747.4 276.7 642 276.7 512s105.4-235.4 235.4-235.4S747.5 382 747.5 512 642.1 747.4 512.1 747.4z m0 0" fill="#c6c6c6" p-id="9538"></path><path d="M372.6 512c0 77 62.4 139.4 139.4 139.4S651.4 589 651.4 512 589 372.6 512 372.6c-76.9 0-139.4 62.4-139.4 139.4z m0 0" fill="#c6c6c6" p-id="9539"></path><path d="M1 759.3l989.7-552.6 32.3 57.9L33.3 817.2 1 759.3z m0 0" fill="#c6c6c6" p-id="9540"></path></svg>
    </div>
    <div v-else @click='toggleFn("password")'>
      <svg t="1603980692285" class="icon" viewBox="0 0 1434 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10414" width="18" height="18"><path d="M716.8 0C391.0656 0 110.6944 211.5584 0 512c110.6944 300.4416 391.0656 512 716.8 512s606.1056-211.5584 716.8-512C1322.5984 211.5584 1042.5344 0 716.8 0zM716.8 853.1968C534.2208 853.1968 391.0656 702.976 391.0656 512 391.0656 320.7168 534.528 170.8032 716.8 170.8032c182.272 0 325.7344 150.2208 325.7344 341.1968C1042.5344 703.2832 899.3792 853.1968 716.8 853.1968zM716.8 307.2C606.1056 307.2 521.3184 396.0832 521.3184 512c0 115.9168 84.7872 204.8 195.4816 204.8 110.6944 0 195.4816-88.8832 195.4816-204.8C912.2816 396.0832 827.4944 307.2 716.8 307.2z" p-id="10415" fill="#c6c6c6"></path></svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    inputType: {
      type: String,
      default: 'password'
    }
  },
  methods: {
    toggleFn(val) {
      this.$emit('change', val)
    }
  }
}
</script>
import http from './http.js'
import Config from './config';
const apiHeader = Config.apiUrl
const apiHeaderS = Config.apiUrlShort

export default {
  // 用户注册接口
  register: (data) => http.post(`${apiHeader}/user/register`, {data}),
  // 用户登录接口
  login: data => http.post(`${apiHeader}/user/registerLogin`, {data}),
  getVerCode: data => http.post(`${apiHeader}/sms/send`, {
    data: {
      bizType: 1,
      ...data,
    }
  }),
  // 重置密码接口
  forgetPasswordReset: data => http.post(`${apiHeader}/user/forgetPasswordReset`, {data}),
  // 用户查询多公司信息
  queryCompanyInfoList: data => http.post(`${apiHeader}/session/company/queryCompanyInfoList`, {data}),
  // 用户信息查询接口
  queryUserInfo: data => http.post(`${apiHeader}/session/user/queryUserInfo`, {data}),
  // 修改密码接口
  changePassword: data => http.post(`${apiHeader}/session/user/changePassword`, {data}),
  // 用户退出登录接口
  loginOut: data => http.post(`${apiHeader}/session/user/loginOut`, {data}),
  // 修改用户信息接口（用户名）
  modifyInfo: data => http.post(`${apiHeader}/session/user/modifyInfo`, {data}),
  // 用户加入公司接口
  joinCompany: data => http.post(`${apiHeader}/session/company/joinCompany`, {data}),
  // 消息中心提醒状态接口
  queryRemindStatus: data => http.post(`${apiHeader}/session/notice/queryRemindStatus`, {data}),
  // getAuth
  getAuth: data => http.post(`${apiHeader}/session/auth/queryUserAuthList`, {data}),

  writeOff:data=>http.post(`${apiHeader}/session/user/closeAccount`,{data}),



  companyUserList:data=>http.post(`${apiHeader}/session/company/user/pageQueryList`,{data}),

  companyUserPass:data=>http.post(`${apiHeader}/session/company/user/pass`,{data}),
  companyUserReject:data=>http.post(`${apiHeader}/session/company/user/reject`,{data}),

  companyDepts:data=>http.post(`${apiHeader}/session/company/department/list`,{data}),
  companyRoles:data=>http.post(`${apiHeader}/session/company/role/list`,{data}),


  generateQrCode:data=>http.post(`${apiHeader}/qrcode/generate`,{data}),
  getLoginInfo:data=>http.post(`${apiHeader}/qrcode/getLoginInfo`,{data}),


  getWxSignature:data=>http.get(`${apiHeaderS}/config`,{data}),

}

import userApi from '@/api/user'

export default {
  methods: {
    getAuth(id) {
      return new Promise((resolve) => {
        userApi.getAuth({
          companyId: id
        }).then(res => {
          if (res.data) {
            this.$global.setItem('auth', res.data)
            resolve(true)
          } else {
            resolve(false)
          }
        }).catch(()=> {
          resolve(false)
        })
      })
    }
  }
}